exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-om-oss-jsx": () => import("./../../../src/pages/om-oss.jsx" /* webpackChunkName: "component---src-pages-om-oss-jsx" */),
  "component---src-pages-oppettider-jsx": () => import("./../../../src/pages/oppettider.jsx" /* webpackChunkName: "component---src-pages-oppettider-jsx" */),
  "component---src-pages-personal-jsx": () => import("./../../../src/pages/personal.jsx" /* webpackChunkName: "component---src-pages-personal-jsx" */),
  "component---src-pages-prislista-jsx": () => import("./../../../src/pages/prislista.jsx" /* webpackChunkName: "component---src-pages-prislista-jsx" */),
  "component---src-pages-produkter-jsx": () => import("./../../../src/pages/produkter.jsx" /* webpackChunkName: "component---src-pages-produkter-jsx" */)
}

